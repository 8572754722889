import React, { useState, useEffect } from 'react';
import { Box, Button, Stepper, Step, StepLabel, TextField, Typography, Select, MenuItem, FormControl, InputLabel, useMediaQuery, Snackbar, Alert, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {doc, setDoc} from 'firebase/firestore';
import { db } from '../../../firebase'; // Your Firebase config file
import { formatNumberWithCommas } from '../../../Helper/Helper';
import {useAuth} from '../../../context/AuthContext';
import { fetchProjects, fetchSalesAgents, fetchSources, fetchProjectDealById } from '../../../services/Services';

const steps = [
  'Basic Information',
  'SPA & Pricing Information',
  'Loan & Referral Information',
  'Documents',
  'Sales Agent & Remarks'
];

const agreementStatus = [
  {
    id: 1,
    status: 'Pending',
  },
  {
    id: 2,
    status: 'Signed',
  },
];

const EditProjectDealPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [sources, setSources] = useState([]);
  const { user } = useAuth(); 
  const { id } = useParams();
  const [projects, setProjects] = useState([]); // State to store the projects
  const [salesAgents, setSalesAgents] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isRebatePercentage, setIsRebatePercentage] = useState(false); // Default to RM
  const [formData, setFormData] = useState( {
    lotNumber: '',
    bookingDate: '',
    projectId: '',
    purchaserName: '',
    spaPrice: '',
    nettPrice: '',
    rebate1: { value: '', type: 'RM' }, 
    rebate2: { value: '', type: 'RM' },
    spaDate: '',
    status: '',
    source: '',
    referralName: '',
    coAgency: '',
    loanMargin: '',
    loLaSignDate: '',
    loLaStatus: '',
    bookingFormUrls: [], // Now an array to store multiple booking forms
    spaCopyUrls: [],
    loLaSignCopyUrls: [],
    cancellationFormUrls: [],
    salesAgentShare: [{ uid: 'user-uid', sharePercentage: '100' }],
    remark: '',
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    comments: '',
    isRebatePercentage1: false,
    isRebatePercentage2: false,
  });

  // Fetch projects from Firebase
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [projectsData, sourcesData, agentsData, projectDealData] = await Promise.all([
          fetchProjects(),
          fetchSources(),
          fetchSalesAgents(),
          fetchProjectDealById(id),
        ]);
  
        setProjects(projectsData);
        setSources(sourcesData);
        setSalesAgents(agentsData);
  
        if (projectDealData) {
          setFormData({
            ...projectDealData,
            id, 
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [id]);  

  useEffect(() => {
    if (formData.rebate1 && typeof formData.rebate1 === 'object') {
      setFormData((prev) => ({
        ...prev,
        rebate1: {
          value: formData.rebate1.value || '', // Ensure value exists
          type: formData.rebate1.type || 'RM',
        },
      }));
    }
    if (formData.rebate2 && typeof formData.rebate2 === 'object') {
      setFormData((prev) => ({
        ...prev,
        rebate2: {
          value: formData.rebate2.value || '', // Ensure value exists
          type: formData.rebate2.type || 'RM',
        },
      }));
    }
  }, [formData.rebate1, formData.rebate2]);

  const handleNext = () => {
    if (!isStepValid()) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleRebateChange = (event, rebateKey) => {
    const { value } = event.target;
  
    setFormData((prev) => ({
      ...prev,
      [rebateKey]: {
        ...prev[rebateKey], // Retain existing `type`
        value: value === "" ? "" : formatRebateValue(value, prev[rebateKey].type),
      },
    }));
  };
  
  const formatRebateValue = (value, type) => {
    if (type === "%") {
      const percentageValue = parseFloat(value);
      return !isNaN(percentageValue) && percentageValue >= 0 && percentageValue <= 100
        ? percentageValue.toString()
        : "";
    } else {
      return value.replace(/[^0-9.]/g, ""); // Allow only numbers for RM
    }
  };  

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = async (event) => {
    const { name, value, type, checked, files } = event.target;
    if (files && files[0]) {
      // File upload handling
      try {
        const file = files[0];
        const storage = getStorage(); // Initialize Firebase Storage
        const fileRef = ref(storage, `documents/${file.name}`);
        
        // Upload file to Firebase Storage
        await uploadBytes(fileRef, file);
        const fileUrl = await getDownloadURL(fileRef);
  
        // Update formData with the file URL
        setFormData((prev) => ({
          ...prev,
          [name]: fileUrl,
        }));
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    else if (name === 'rebate') {
      if (value === '') {
        setFormData((prev) => ({ ...prev, rebate: '' }));
      } else if (isRebatePercentage) {
        const percentageValue = parseFloat(value);
        if (!isNaN(percentageValue) && percentageValue >= 0 && percentageValue <= 100) {
          setFormData((prev) => ({ ...prev, rebate: value }));
        }
      } else {
        const numericValue = value.replace(/[^0-9.]/g, '');
        setFormData((prev) => ({ ...prev, rebate: formatNumberWithCommas(numericValue) }));
      }
    } else if (name === 'projectId') {
      // Update projectId and reset the selected project name
      const selectedProject = projects.find((project) => project.id === value);
      setFormData((prev) => ({
        ...prev,
        projectId: selectedProject.id,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const updateInFirebase = async (status) => {
    try {
      if (!formData.id) {
        console.error('Document ID is missing');
        return;
      }
  
      const updatedFormData = {
        ...formData,
        status,
        updatedBy: user?.uid || 'unknown',
        updatedAt: new Date().toISOString(),
        rebate1: {
          value: formData.rebate1.value,
          type: formData.isRebatePercentage1 ? '%' : 'RM',
        },
        rebate2: {
          value: formData.rebate2.value,
          type: formData.isRebatePercentage2 ? '%' : 'RM',
        },
      };
  
      const docRef = doc(db, 'projectDeals', formData.id); // Use the correct collection name
      await setDoc(docRef, updatedFormData);
  
      setSnackbarMessage(status === 3 ? 'Submitted successfully!' : 'Draft saved successfully!');
      setOpenSnackbar(true);
      navigate('/sales', { state: { tabIndex: 0 } });
    } catch (error) {
      console.error('Error updating document: ', error);
      alert('Failed to update data. Please try again.');
    }
  };

  const handleSubmit = async () => {
   
    if (!isStepValid()) return;
    updateInFirebase(3); //Ready To Convert
    navigate('/sales'); // Redirect after submission
  };
  
  const handleDraft = () => {
    updateInFirebase(1); //Drafted
    navigate('/sales'); // Redirect after submission
  };

  const handleAgentChange = (index, field, value) => {
    const updatedAgents = [...formData.salesAgentShare];
    updatedAgents[index][field] = value;
    setFormData({ ...formData, salesAgentShare: updatedAgents });
  };

  const handleMultiFileUpload = async (event, fieldName) => {
      const files = event.target.files;
      if (!files) return;
    
      const uploadedFiles = [];
      const storageRef = getStorage(); // Ensure you have Firebase Storage initialized
    
      for (let file of files) {
        const fileRef = ref(storageRef, `documents/${file.name}`);
        await uploadBytes(fileRef, file);
        const fileUrl = await getDownloadURL(fileRef);
    
        uploadedFiles.push({ url: fileUrl, name: file.name });
      }
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: [...prevFormData[fieldName], ...uploadedFiles], // Append new files
      }));
    };
    
    
    const handleRemoveFile = (index, fieldName) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: prevFormData[fieldName].filter((_, i) => i !== index), // Remove by index
      }));
    };

  const addAgent = () => {
    setFormData({
      ...formData,
      salesAgentShare: [...formData.salesAgentShare, { uid: '', sharePercentage: '' }],
    });
  };

  const isStepValid = () => {
    // Validation logic for each step
    if (activeStep === 0) {
      return formData.lotNumber && formData.bookingDate && formData.projectId && formData.purchaserName;
    }
    if (activeStep === 1) {
      return formData.spaPrice && formData.nettPrice && formData.rebate1 && formData.rebate2;
    }
    if (activeStep === 2) {
      return formData.source;
    }
    if (activeStep === 3) {
      return formData.bookingFormUrls.length > 0;
    }
    if (activeStep === 4) {
      return formData.salesAgentShare.every(agent => agent.uid && agent.sharePercentage);
    }
    return true;
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0: // Basic Information
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">Basic Information</Typography>
            <TextField 
              fullWidth 
              label="Purchaser Name" 
              name="purchaserName" 
              value={formData.purchaserName} 
              onChange={handleChange} 
              margin="normal" 
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Project *</InputLabel>
              <Select name="projectId" value={formData.projectId} onChange={handleChange} label="Project">
                {projects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField fullWidth label="Lot Number" name="lotNumber" value={formData.lotNumber} onChange={handleChange} margin="normal" />
            <TextField 
              sx={{ width: isMobile ? '100%' : '200px' }}
              label="Booking Date" 
              name="bookingDate" 
              type="date" 
              InputLabelProps={{ shrink: true }} 
              value={formData.bookingDate} 
              onChange={handleChange} margin="normal"
            />
          </Box>
        );
  
        case 1: // SPA & Pricing Information
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: "1px solid #ddd", borderRadius: "8px", padding: "16px", backgroundColor: "#f9f9f9" }}>
            <Typography variant="h6">SPA & Pricing Information</Typography>
            <TextField fullWidth label="SPA Price *" name="spaPrice" value={formData.spaPrice} onChange={handleChange} margin="normal" />
            <TextField fullWidth label="Nett Price *" name="nettPrice" value={formData.nettPrice} onChange={handleChange} margin="normal" />
      
            {/* Rebate 1 Input */}
            <Box display="flex" alignItems="center" mt={2}>
              <Typography variant="body2" sx={{ mr: 2 }}>Rebate 1 in</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button variant={!formData.isRebatePercentage1 ? "contained" : "outlined"} onClick={() => setFormData({ ...formData, isRebatePercentage1: false, rebate1: "" })}>
                  RM
                </Button>
                <Button variant={formData.isRebatePercentage1 ? "contained" : "outlined"} onClick={() => setFormData({ ...formData, isRebatePercentage1: true, rebate1: "" })}>
                  %
                </Button>
              </Box>
            </Box>
            <TextField
              fullWidth
              label={`Rebate 1 * (${formData.rebate1.type})`}
              name="rebate1"
              value={formData.rebate1.value} // ✅ Correctly extracts `value`
              onChange={(e) => handleRebateChange(e, 'rebate1')}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
      
            {/* Rebate 2 Input */}
            <Box display="flex" alignItems="center" mt={2}>
              <Typography variant="body2" sx={{ mr: 2 }}>Rebate 2 in</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button variant={!formData.isRebatePercentage2 ? "contained" : "outlined"} onClick={() => setFormData({ ...formData, isRebatePercentage2: false, rebate2: "" })}>
                  RM
                </Button>
                <Button variant={formData.isRebatePercentage2 ? "contained" : "outlined"} onClick={() => setFormData({ ...formData, isRebatePercentage2: true, rebate2: "" })}>
                  %
                </Button>
              </Box>
            </Box>
            <TextField
              fullWidth
              label={`Rebate 2 * (${formData.rebate2.type})`}
              name="rebate2"
              value={formData.rebate2.value} // ✅ Correctly extracts `value`
              onChange={(e) => handleRebateChange(e, 'rebate2')}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        );      
  
      case 2: // Loan & Referral Information
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">Loan & Referral Information</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Source</InputLabel>
              <Select name="source" value={formData.source} onChange={handleChange} label="Source">
                {sources.map((source) => (
                  <MenuItem key={source.id} value={source.name}>{source.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField fullWidth label="Referral Name" name="referralName" value={formData.referralName} onChange={handleChange} margin="normal" />
            <TextField fullWidth label="Co-Agency" name="coAgency" value={formData.coAgency} onChange={handleChange} margin="normal" />
            <TextField 
              fullWidth 
              label="Loan Margin" 
              name="loanMargin" 
              value={formData.loanMargin} 
              onChange={(event) => {
                // Ensure the value is between 0 and 100
                const numericValue = parseFloat(event.target.value);
                if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
                  setFormData({ ...formData, loanMargin: event.target.value });
                } else if (event.target.value === '') { // Allow clearing the input
                  setFormData({ ...formData, loanMargin: '' });
                }
              }}
              type="number"
              inputProps={{
                min: 0,
                max: 100,
                step: 0.01 // Allows decimal values, adjust as needed
              }}
              margin="normal" />
            <FormControl fullWidth margin="normal">
              <InputLabel>LO/LA Status</InputLabel>
              <Select name="loLaStatus" value={formData.loLaStatus} onChange={handleChange} label="LO/LA Status">
                {agreementStatus.map((status) => (
                  <MenuItem key={status.id} value={status.status}>{status.status}</MenuItem> // Use status.status for the displayed value
                ))}
              </Select>
            </FormControl>
            <TextField sx={{ width: isMobile ? '100%' : '200px' }} label="LO/LA Sign Date" name="loLaSignDate" type="date" InputLabelProps={{ shrink: true }} value={formData.loLaSignDate} onChange={handleChange} margin="normal" />
            
          </Box>
        );
  
        case 3: // Documents
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            sx={{ border: "1px solid #ddd", borderRadius: "8px", padding: "16px", backgroundColor: "#f9f9f9" }}
          >
            <Typography variant="h6" sx={{ mb: "10px" }}>
              Documents
            </Typography>
      
            {/* Booking Form URL */}
            <Typography variant="body1" gutterBottom>
              Booking Form *
            </Typography>
            <Box display="flex" flexDirection="column" gap={1} sx={{ mt: 1 }}>
              {/* Display uploaded booking form links */}
              {formData.bookingFormUrls.length > 0 ? (
                formData.bookingFormUrls.map((file, index) => (
                  <Box key={index} display="flex" alignItems="center" gap={2}>
                    <Button
                      variant="text"
                      component="a"
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.name} {/* Show actual file name */}
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleRemoveFile(index, "bookingFormUrls")}>
                      Remove
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography color="textSecondary">No documents uploaded</Typography>
              )}
              {/* Upload new files */}
              <Button variant="outlined" component="label" sx={{ width: "200px" }}>
                Upload Documents
                <input
                  type="file"
                  name="bookingFormUrls"
                  multiple // Enable multiple file selection
                  onChange={(e) => handleMultiFileUpload(e, "bookingFormUrls")}
                  style={{ display: "none" }}
                />
              </Button>
            </Box>
      
            {/* SPA Copy URL */}
            <Typography variant="body1" gutterBottom sx={{ mt: "20px" }}>
              SPA Copy
            </Typography>
            <Box display="flex" flexDirection="column" gap={1} sx={{ mt: 1 }}>
              {formData.spaCopyUrls.length > 0 ? (
                formData.spaCopyUrls.map((file, index) => (
                  <Box key={index} display="flex" alignItems="center" gap={2}>
                    <Button variant="text" component="a" href={file.url} target="_blank">
                      {file.name}
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleRemoveFile(index, "spaCopyUrls")}>
                      Remove
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography color="textSecondary">No documents uploaded</Typography>
              )}
              <Button variant="outlined" component="label" sx={{ width: "200px" }}>
                Upload Documents
                <input type="file" name="spaCopyUrls" multiple onChange={(e) => handleMultiFileUpload(e, "spaCopyUrls")} style={{ display: "none" }} />
              </Button>
            </Box>
      
            {/* LO/LA Sign Copy URL */}
            <Typography variant="body1" gutterBottom sx={{ mt: "20px" }}>
              LO/LA Sign Copy
            </Typography>
            <Box display="flex" flexDirection="column" gap={1} sx={{ mt: 1 }}>
              {formData.loLaSignCopyUrls.length > 0 ? (
                formData.loLaSignCopyUrls.map((file, index) => (
                  <Box key={index} display="flex" alignItems="center" gap={2}>
                    <Button variant="text" component="a" href={file.url} target="_blank">
                      {file.name}
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleRemoveFile(index, "loLaSignCopyUrls")}>
                      Remove
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography color="textSecondary">No documents uploaded</Typography>
              )}
              <Button variant="outlined" component="label" sx={{ width: "200px" }}>
                Upload Documents
                <input type="file" name="loLaSignCopyUrls" multiple onChange={(e) => handleMultiFileUpload(e, "loLaSignCopyUrls")} style={{ display: "none" }} />
              </Button>
            </Box>

            {/* Cancellation Form URL */}
            <Typography variant="body1" gutterBottom sx={{ mt: "20px" }}>
              Cancellation Form
            </Typography>
            <Box display="flex" flexDirection="column" gap={1} sx={{ mt: 1 }}>
              {formData.cancellationFormUrls.length > 0 ? (
                formData.cancellationFormUrls.map((file, index) => (
                  <Box key={index} display="flex" alignItems="center" gap={2}>
                    <Button variant="text" component="a" href={file.url} target="_blank">
                      {file.name}
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleRemoveFile(index, "cancellationFormUrls")}>
                      Remove
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography color="textSecondary">No documents uploaded</Typography>
              )}
              <Button variant="outlined" component="label" sx={{ width: "200px" }}>
                Upload Documents
                <input type="file" name="cancellationFormUrls" multiple onChange={(e) => handleMultiFileUpload(e, "cancellationFormUrls")} style={{ display: "none" }} />
              </Button>
            </Box>

          </Box>
        );

      case 4: // Sales Agent & Remarks
      return (
        <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h6">Sales Agent</Typography>
                  <Button variant="contained" color="success" onClick={addAgent}>
                    + Add Agent
                  </Button>
                </Box>
                {formData.salesAgentShare.map((agent, index) => (
                  <Box key={index} sx={{ mb: 2, border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#fff' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <Typography variant="body1">Sales Agent: {index + 1}</Typography>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          const updatedAgents = formData.salesAgentShare.filter((_, i) => i !== index);
                          setFormData({ ...formData, salesAgentShare: updatedAgents });
                        }}
                        sx={{ minWidth: '40px' }}
                      >
                        -
                      </Button>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                      <Box flex={1}>
                        <Typography variant="subtitle2" gutterBottom>Sales Agent</Typography>
                        <TextField
                          select
                          fullWidth
                          value={agent.uid}
                          onChange={(e) => handleAgentChange(index, 'uid', e.target.value)}
                          SelectProps={{ native: true }}
                        >
                          {/* Dynamically generate options from salesAgents */}
                          <option value="">Select Agent</option>
                          {salesAgents.map((salesAgent) => (
                            <option key={salesAgent.uid} value={salesAgent.uid}>
                              {salesAgent.name}
                            </option>
                          ))}
                        </TextField>
                      </Box>
                      <Box sx={{ width: '150px' }}>
                        <Typography variant="subtitle2" gutterBottom>Commission Share (%)</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          value={agent.sharePercentage}
                          onChange={(e) => handleAgentChange(index, 'sharePercentage', e.target.value)}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
                <Typography variant="subtitle2" gutterBottom mt={2}>Remarks</Typography>
                <TextField
                  fullWidth
                  name="remark"
                  value={formData.remark}
                  onChange={handleChange}
                  placeholder="Enter any remarks (optional)"
                  multiline
                  rows={3}
                  variant="outlined"
                />
              </Box>
      );
  
      default:
        return 'Unknown Step';
    }
  };
  

  return (
    <Box sx={{ width: '100%' }}>
      <Button variant="contained" color="primary" onClick={() => navigate('/sales', { state: { tabIndex: 0 } })} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        Update Project Deal
      </Typography>
      <Stepper activeStep={activeStep} sx={{ mt: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {renderStepContent(activeStep)}
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="outlined" color="primary" onClick={handleDraft}>
              Draft
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isStepValid()}>
              Ready To Convert
            </Button>
          </Box>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={!isStepValid()}>
            Next
          </Button>
        )}

      </Box>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position Snackbar at the top center
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditProjectDealPage;
