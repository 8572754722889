import React, { useState, useEffect } from 'react';
import { CircularProgress, Box, Grid, Typography, Button, Avatar, TextField, CardContent, Card, Divider} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase'; // Your Firebase configuration
import { formatNumberWithCommas } from '../../../Helper/Helper';
import useAuth from '../../../hooks/useAuth';
import { sendEmail } from '../../../Helper/EmailNotification';
import CustomModal from '../../../components/CustomModal';
import { fetchEmailTemplates } from '../../../services/Services';

// Helper Function to Display Multiple Documents
const renderDocumentsList = (label, documents = []) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body1" fontWeight="bold">{label}</Typography>
      {documents.length > 0 ? (
        documents.map((file, index) => (
          <Button key={index} variant="text" component="a" href={file.url} target="_blank" sx={{ display: 'block', textAlign: 'left' }}>
            {file.name}
          </Button>
        ))
      ) : (
        <Typography color="textSecondary">No documents uploaded</Typography>
      )}
    </Box>
  );
};

const ViewRequestProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectDeal, setProjectDeal] = useState(null);
  const [agentNames, setAgentNames] = useState({});
  const [openModal, setOpenModal] = useState(false); // State to manage modal visibility
  const [comments, setComments] = useState(''); // State to capture admin comments
  const [emailTemplates, setEmailTemplates] = useState({});
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState('N/A'); 
  const [projectComPercentage, setProjectComPercentage] = useState(0);
  const [usersData, setUsersData] = useState({});

  useEffect(() => {
    const fetchProjectName = async (projectId) => {
      try {
        const projectRef = doc(db, 'projects', projectId);
        const projectSnap = await getDoc(projectRef);
        if (projectSnap.exists()) {
          setProjectName(projectSnap.data().name || 'N/A'); // Set project name
          setProjectComPercentage(projectSnap.data().commissionPercentage)
        } else {
          console.error('Project not found!');
        }
      } catch (error) {
        console.error('Error fetching project name:', error);
      }
    };

    const fetchProjectDeal = async () => {
      try {
        const docRef = doc(db, 'projectDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const dealData = docSnap.data();
          setProjectDeal(dealData);
          if (dealData.projectId) {
            fetchProjectName(dealData.projectId);
          }
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching project deal:', error);
      }
    };

    const fetchAgentNames = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const names = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          names[doc.id] = data.name;
        });
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching agent names:', error);
      }
    };

    const fetchUsersData = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const users = {};
        const names = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          users[doc.id] = data; // Store full user data
          names[doc.id] = data.name; // Store agent names separately
        });

        setUsersData(users);
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };

    const loadEmailTemplates = async () => {
      try {
        const templates = await fetchEmailTemplates(); // Use the reusable service function
        setEmailTemplates(templates);
      } catch (error) {
        console.error('Error loading email templates:', error);
      }
    };
    
    fetchProjectDeal();
    fetchAgentNames();
    loadEmailTemplates();
    fetchUsersData();
  }, [id]);

  

  const handleBackButton = () => {
    navigate('/requests', { state: { tabIndex: 0 } });
  };

  const handleStatusUpdate = async (status, adminComments = '') => {
    setIsLoading(true);
  
    try {
      const docRef = doc(db, 'projectDeals', id);
      await updateDoc(docRef, { status, comments: adminComments });
      setProjectDeal((prev) => ({ ...prev, status, comments: adminComments })); // Update local state
  
      // Create Claim data
      if (projectDeal.salesAgentShare && projectDeal.salesAgentShare.length > 0) {
        const totalCommissionBase =
          (Number(projectDeal.nettPrice.replace(/,/g, '')) * projectComPercentage) / 100;
  
        const emailTemplateKey = status === 7 ? 'Sales_Approved' : 'Sales_Returned';
        const emailTemplate = emailTemplates[emailTemplateKey];
  
        if (emailTemplate) {
          // Use Promise.all for sending emails asynchronously
          await Promise.all(
            projectDeal.salesAgentShare.map(async (agent) => {
              const userDetails = usersData[agent.uid];
              if (userDetails && userDetails.email) {
                const personalizedMessage = emailTemplate.body
                .replace('{{salesId}}', projectDeal.projectDealId)
                .replace('{{comments}}', adminComments);
                await sendEmail(userDetails, personalizedMessage, emailTemplate.subject);
              } else {
                console.error(`User details not found or email missing for agent UID: ${agent.uid}`);
              }
            })
          );
        }
        console.log("email sent")
        const agentClaims = projectDeal.salesAgentShare.map((agent) => {
          const sharePercentage = agent.sharePercentage / 100;
  
          // Calculate commission based on number of agents
          const commissionAmount =
            projectDeal.salesAgentShare.length === 1
              ? totalCommissionBase
              : totalCommissionBase * sharePercentage;
  
          return {
            agentUid: agent.uid,
            agentName: agentNames[agent.uid] || 'Unknown Agent',
            commissionPercentage: sharePercentage * 100,
            commissionAmount: parseFloat(commissionAmount.toFixed(2)),
            nettPrice: projectDeal.nettPrice,
            projectComPercentage: projectComPercentage,
            isClaimed: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          };
        });
  
        // Filter out null values (if any agent data is missing)
        const validAgentClaims = agentClaims.filter((claim) => claim !== null);
  
        // Create a new projectClaim document in Firebase
        const projectClaimRef = doc(db, 'projectClaims', id); // Use the same ID as the project deal
        await setDoc(projectClaimRef, {
          projectDealId: projectDeal.projectDealId,
          agentClaims: validAgentClaims,
          createdAt: new Date().toISOString(),
        });
  
        console.log('Project claims successfully created.');
      }
  
      alert(`Status updated to ${status === 7 ? 'Converted' : 'Returned with comments.'}`);
      navigate('/requests', { state: { tabIndex: 0 } });
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setComments(''); // Reset comments
  };

  const handleSubmitReturn = () => {
    handleStatusUpdate(6, comments); // Update status to Returned (6) with comments
    handleCloseModal(); // Close the modal
  };

  if (!projectDeal) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2 }}
      >
        Back
      </Button>
      <Typography variant="h6" gutterBottom>
        Project Request Details
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Purchaser Name"
            variant="outlined"
            fullWidth
            value={projectDeal.purchaserName || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Project"
            variant="outlined"
            fullWidth
            value={projectName}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Lot Number"
            variant="outlined"
            fullWidth
            value={projectDeal.lotNumber || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="SPA Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(projectDeal.spaPrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Nett Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(projectDeal.nettPrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          {/* Rebate 1 */}
          <TextField
            label={projectDeal.rebate1?.type === '%' ? 'Rebate 1 (%)' : 'Rebate 1 (RM)'}
            fullWidth
            value={projectDeal.rebate1?.value || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          {/* Rebate 2 */}
          <TextField
            label={projectDeal.rebate2?.type === '%' ? 'Rebate 2 (%)' : 'Rebate 2 (RM)'}
            fullWidth
            value={projectDeal.rebate2?.value || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="SPA Date"
            variant="outlined"
            fullWidth
            value={projectDeal.spaDate || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Source"
            variant="outlined"
            fullWidth
            value={projectDeal.source || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Referral Name"
            variant="outlined"
            fullWidth
            value={projectDeal.referralName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Co-Agency"
            variant="outlined"
            fullWidth
            value={projectDeal.coAgency || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Loan Margin"
            variant="outlined"
            fullWidth
            value={projectDeal.loanMargin || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="LO/LA Status"
            variant="outlined"
            fullWidth
            value={projectDeal.loLaStatus || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="LO/LA Sign Date"
            variant="outlined"
            fullWidth
            value={projectDeal.loLaSignDate || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>
      </Grid>
      {/* Divider */}
      <Divider sx={{ my: 3 }} />

      {/* Documents Section */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Documents
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {renderDocumentsList('Booking Form', projectDeal.bookingFormUrls)}
          {renderDocumentsList('SPA Copy', projectDeal.spaCopyUrls)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDocumentsList('LO/LA Signed Copy', projectDeal.loLaSignCopyUrls)}
          {renderDocumentsList('Cancellation Form', projectDeal.cancellationFormUrls)}
        </Grid>
      </Grid>
      {/* Agents Information */}
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {projectDeal.salesAgentShare.map((agent, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Avatar sx={{ bgcolor: '#3f51b5', mr: 2 }}>
                {agentNames[agent.uid]?.[0] || 'A'}
              </Avatar>
              <Box>
                <Typography>
                  Sales Agent: {agentNames[agent.uid] || agent.uid}
                </Typography>
                <Typography>
                  Commission Share: {agent.sharePercentage}%
                </Typography>
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>

      {/* Approve and Return Buttons */}
      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleStatusUpdate(7)} // Approve
        >
          {isLoading ?  <CircularProgress /> : 'Convert'}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOpenModal} // Open modal for Return
        >
          Return
        </Button>
      </Box>

      {/* Return Comments Modal */}
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        onSave={handleSubmitReturn}
        title="Provide Comments for Return"
        saving={isLoading}
      >
        <TextField
          label="Comments"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </CustomModal>
    </Box>
  );
};

export default ViewRequestProject;
