import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Avatar, TextField, CardContent, Card, Divider, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { formatNumberWithCommas } from '../../../Helper/Helper';
import { fetchProjectDealById, fetchSalesAgentsName } from '../../../services/Services';

const ViewProjectDealPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectDeal, setProjectDeal] = useState(null);
  const [agentNames, setAgentNames] = useState({});
  const [projectName, setProjectName] = useState('N/A');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dealData = await fetchProjectDealById(id);
        setProjectDeal(dealData);

        const names = await fetchSalesAgentsName();
        setAgentNames(names);

        if (dealData?.projectId) {
          const projectRef = doc(db, 'projects', dealData.projectId);
          const projectSnap = await getDoc(projectRef);
          if (projectSnap.exists()) {
            setProjectName(projectSnap.data().name || 'N/A');
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  if (!projectDeal) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/sales', { state: { tabIndex: 0 } });
  };

  return (
    <Box sx={{p:3, margin: 'auto', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
      {/* Sticky Note for Comments */}
      {projectDeal.comments && projectDeal.status === 6 && (
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: 100,
            right: 16,
            width: 800,
            padding: 2,
            backgroundColor: '#FFF176', // Yellow color
            color: '#000',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Admin Comments:
          </Typography>
          <Typography variant="body2">{projectDeal.comments}</Typography>
        </Paper>
      )}

      <Button variant="contained" color="primary" onClick={handleBackButton} sx={{ mb: 2 }}>
        Back
      </Button>

      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Project Deal Information
      </Typography>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* Left Column */}
        <Grid item xs={12} sm={6}>
          <TextField label="Purchaser Name" fullWidth value={projectDeal.purchaserName || 'N/A'} InputProps={{ readOnly: true }} />
          <TextField label="Project" fullWidth value={projectName} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
          <TextField label="Lot Number" fullWidth value={projectDeal.lotNumber || 'N/A'} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
          <TextField label="SPA Price (RM)" fullWidth value={formatNumberWithCommas(projectDeal.spaPrice || 0)} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
          <TextField label="Nett Price (RM)" fullWidth value={formatNumberWithCommas(projectDeal.nettPrice || 0)} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />

          {/* Rebate 1 */}
          <TextField
            label={projectDeal.rebate1?.type === '%' ? 'Rebate 1 (%)' : 'Rebate 1 (RM)'}
            fullWidth
            value={projectDeal.rebate1?.value || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          {/* Rebate 2 */}
          <TextField
            label={projectDeal.rebate2?.type === '%' ? 'Rebate 2 (%)' : 'Rebate 2 (RM)'}
            fullWidth
            value={projectDeal.rebate2?.value || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField label="SPA Date" fullWidth value={projectDeal.spaDate || 'N/A'} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={6}>
          <TextField label="Source" fullWidth value={projectDeal.source || 'N/A'} InputProps={{ readOnly: true }} />
          <TextField label="Referral Name" fullWidth value={projectDeal.referralName || 'N/A'} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
          <TextField label="Co-Agency" fullWidth value={projectDeal.coAgency || 'N/A'} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
          <TextField label="Loan Margin" fullWidth value={projectDeal.loanMargin || 'N/A'} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
          <TextField label="LO/LA Status" fullWidth value={projectDeal.loLaStatus || 'N/A'} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
          <TextField label="LO/LA Sign Date" fullWidth value={projectDeal.loLaSignDate || 'N/A'} InputProps={{ readOnly: true }} sx={{ mt: 2 }} />
        </Grid>
      </Grid>

      {/* Divider */}
      <Divider sx={{ my: 3 }} />

      {/* Documents Section */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Documents
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {renderDocumentsList('Booking Form', projectDeal.bookingFormUrls)}
          {renderDocumentsList('SPA Copy', projectDeal.spaCopyUrls)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDocumentsList('LO/LA Signed Copy', projectDeal.loLaSignCopyUrls)}
          {renderDocumentsList('Cancellation Form', projectDeal.cancellationFormUrls)}
        </Grid>
      </Grid>

      {/* Divider */}
      <Divider sx={{ my: 3 }} />

      {/* Agents Information */}
      <Card sx={{ mt: 4, borderRadius: '8px', p: 2 }}>
        <CardContent>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Agents Information
          </Typography>
          {projectDeal.salesAgentShare.map((agent, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Avatar sx={{ bgcolor: '#3f51b5', mr: 2 }}>
                {agentNames[agent.uid]?.[0] || 'A'}
              </Avatar>
              <Box>
                <Typography fontWeight="bold">
                  {agentNames[agent.uid] || agent.uid}
                </Typography>
                <Typography>Commission Share: {agent.sharePercentage}%</Typography>
              </Box>
            </Box>
          ))}
          <TextField label="Remarks" fullWidth value={projectDeal.remark || 'N/A'} InputProps={{ readOnly: true }} />
        </CardContent>
      </Card>
    </Box>
  );
};

// Helper Function to Display Multiple Documents
const renderDocumentsList = (label, documents = []) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body1" fontWeight="bold">{label}</Typography>
      {documents.length > 0 ? (
        documents.map((file, index) => (
          <Button key={index} variant="text" component="a" href={file.url} target="_blank" sx={{ display: 'block', textAlign: 'left' }}>
            {file.name}
          </Button>
        ))
      ) : (
        <Typography color="textSecondary">No documents uploaded</Typography>
      )}
    </Box>
  );
};

export default ViewProjectDealPage;
